import React from 'react'
import Layout from '../../components/layout'

const BlogTags = () => {
	return (
		<Layout title="Blog Tags">
			<div className="article">
				<h1>Blog Tags</h1>
				<div>This page has been discontinued.</div>
			</div>
		</Layout>
	)
}

export default BlogTags
